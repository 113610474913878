<div *ngIf="form" >
  <form [formGroup]="form" novalidate>
    <div class="form-field">
      <se-po-email
        seFeDataLabel="Email"
        formControlName="email">
      </se-po-email>
    </div>
    <div class="form-field">
      <div class="access-label">
        <se-fe-form-control-label
          seFeDataLabel="Access Level"
          seFeDataLabelFor="access">
        </se-fe-form-control-label>
        <button type="button" seFePopoverTrigger="addGuardianAccessPopover" class="access-popover">
          <se-fe-icon
            seFeDataName="info_circle"
            seFeDataSize="500"
            seFeDataColor="gray-700">
          </se-fe-icon>
        </button>
        <se-fe-popover seFeDataName="addGuardianAccessPopover" [seFeDataFixedPosition]="true">
          Learn more about&ensp;
          <a
            href="https://help.sportsengine.com/en/articles/9265109"
            class="access-popover-link"
            target="_blank">
            access levels.
          </a>
        </se-fe-popover>
      </div>
      <se-fe-form-field-select
        formControlName="access"
        [seFeDataOptions]="accessFieldOptions"
        seFeDataPlaceholder="Select"
        [seFeDataAllowClear]="false">
      </se-fe-form-field-select>
      <se-fe-form-control-errors *ngIf="form.get('access').touched && form.get('access').invalid"
        seFeDataId="add-guardian-error-id"
        [seFeDataMessages]="{
          required: 'This field is required'
        }"
        [seFeDataErrors]="['required']">
      </se-fe-form-control-errors>
    </div>
  </form>
</div>
