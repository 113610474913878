import { APP_BASE_HREF, CurrencyPipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SeAnalyticsModule } from 'se-analytics'
import { SeFeAccordionModule } from 'se-fe-accordion'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeAuthSupportModule } from 'se-fe-auth-support'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeDateTimeModule } from 'se-fe-date-time'
import { SeFeEmptyStateModule } from 'se-fe-empty-state'
import { SeFeHeaderModule } from 'se-fe-header'
import {
  SeFeIconModule,
  seFeIconBadgeVerify,
  seFeIconCalendar,
  seFeIconCameraFill,
  seFeIconCaretDown,
  seFeIconCaretUp,
  seFeIconCaretUpDown,
  seFeIconCheckCircle,
  seFeIconCheckCircleOutline,
  seFeIconChevronDown,
  seFeIconChevronLeft,
  seFeIconChevronRight,
  seFeIconChevronUp,
  seFeIconEnvelopeFilled,
  seFeIconFlag,
  seFeIconInfoCircle,
  seFeIconInvoiceThin,
  seFeIconLock,
  seFeIconLockThin,
  seFeIconMinusCircle,
  seFeIconMore,
  seFeIconMoreVerticle,
  seFeIconPencilFill,
  seFeIconPeopleThin,
  seFeIconPerson,
  seFeIconPhoneFill,
  seFeIconPlusCircle,
  seFeIconQuestionCircle,
  seFeIconQuestionCircleOutline,
  seFeIconQuestionThin,
  seFeIconRemove,
  seFeIconRemoveCircle,
  seFeIconRemoveCircleOutline,
  seFeIconSearchThin,
  seFeIconShieldFill,
  seFeIconSportAlpineSkiing,
  seFeIconSportAmericanFootball,
  seFeIconSportBadminton,
  seFeIconSportBaseball,
  seFeIconSportBasketball,
  seFeIconSportBiathlon,
  seFeIconSportCricket,
  seFeIconSportCrossCountrySkiing,
  seFeIconSportCurling,
  seFeIconSportFieldHockey,
  seFeIconSportFreeski,
  seFeIconSportGolf,
  seFeIconSportGymnastics,
  seFeIconSportIceHockey,
  seFeIconSportInlineSkating,
  seFeIconSportLacrosse,
  seFeIconSportRugby,
  seFeIconSportSkating,
  seFeIconSportSkiJumping,
  seFeIconSportSkiing,
  seFeIconSportSnowboarding,
  seFeIconSportSoccer,
  seFeIconSportSoftball,
  seFeIconSportSpeedSkating,
  seFeIconSportSwimming,
  seFeIconSportTennis,
  seFeIconSportVolleyball,
  seFeIconSportWrestling,
  seFeIconWallet,
  seFeIconWarning,
} from 'se-fe-icon'
import { SeFeImageUploaderModule } from 'se-fe-image-uploader'
import { SeFeLeftNavModule } from 'se-fe-left-nav'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFePageModule } from 'se-fe-page'
import { SeFePaginationModule } from 'se-fe-pagination'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SeFeToastModule } from 'se-fe-toast'
import { SeFeTooltipModule } from 'se-fe-tooltip'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { SeUserSettingsModule } from 'se-user-settings'

import { environment } from '@se-po/shared-environments'

import { SeMySportsengineFeatureCredentialTableModule } from '@se-po/se-my-sportsengine-feature-credential-table'
import { SeMySportsengineFeatureHouseholdModule } from '@se-po/se-my-sportsengine-feature-household'
import { SeMySportsengineFeatureMembershipTableModule } from '@se-po/se-my-sportsengine-feature-membership-table'
import { SeMySportsengineUiUsavLaunchModule } from '@se-po/se-my-sportsengine-ui-usav-launch'
import { SeFeApiModule } from 'se-fe-api'
import { SeFeLinkModule } from 'se-fe-link'
import { AccessDeniedComponent } from './access-denied/access-denied.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BillDetailComponent } from './bills-page/bill-detail/bill-detail.component'
import { BillsListComponent } from './bills-page/bills-list.component'
import { ComponentsModule } from './components'
import { EventCardComponent } from './components/event-card/event-card.component'
import { RsvpListItemComponent } from './components/rsvp-list-item/rsvp-list-item.component'
import { RsvpListComponent } from './components/rsvp-list/rsvp-list.component'
import { DashboardMembershipInvitesComponent } from './dashboard/dashboard-membership-invites/dashboard-membership-invites.component'
import { DashboardMobileCalloutComponent } from './dashboard/dashboard-mobile-callout/dashboard-mobile-callout.component'
import { DashboardPaymentsComponent } from './dashboard/dashboard-payments/dashboard-payments.component'
import { DashboardRecommendedActionsComponent } from './dashboard/dashboard-recommended-actions/dashboard-recommended-actions.component'
import { DashboardScheduleComponent } from './dashboard/dashboard-schedule/dashboard-schedule.component'
import { DashboardTeamsComponent } from './dashboard/dashboard-teams/dashboard-teams.component'
import { DashboardComponent } from './dashboard/dashboard/dashboard.component'
import { MembershipInviteModalComponent } from './dashboard/membership-invite-modal/membership-invite-modal.component'
import { DibsPageComponent } from './dibs-page/dibs-page.component'
import { GroupsPageComponent } from './groups-page/groups-page.component'
import { HomePageComponent } from './home-page/home-page.component'
import { InvalidComponent } from './invalid/invalid.component'
import { MessagesPageComponent } from './messages-page/messages-page.component'
import { ModalComponent } from './modals/modal/modal.component'
import { RsvpModalComponent } from './modals/rsvp-modal/rsvp-modal.component'
import { SyncScheduleComponent } from './modals/sync-schedule/sync-schedule.component'
import { OrderHistoryComponent } from './order-history/order-history.component'
import { NginOrderComponent } from './order/ngin-order.component'
import { OrderDetailComponent } from './order/order-detail.component'
import { SaleActivityCardComponent } from './order/sale-activity-card.component'
import { SaleInfoCardComponent } from './order/sale-info-card.component'
import { OrganizationsPageComponent } from './organizations-page/organizations-page.component'
import { PipesModule } from './pipes/pipes.module'
import { RegistrationDetailComponent } from './registration/registration-detail.component'
import { RegistrationListComponent } from './registration/registration-list.component'
import { ScheduleComponent } from './schedule/schedule.component'
import { TeamsPageComponent } from './teams-page/teams-page.component'
import { TeamsRemindersComponent } from './teams-page/teams-reminders.component'
import { VideoStreamingComponent } from './video-streaming/video-streaming.component'
import { WelcomeComponent } from './welcome/welcome.component'

export const AppSeFeComponentModules = [
  SeFeAccordionModule,
  SeFeAlertModule,
  SeFeAvatarModule,
  SeFeButtonModule,
  SeFeCardModule,
  SeFeChipModule,
  SeFeEmptyStateModule,
  SeFeDateTimeModule,
  SeFeHeaderModule,
  SeFeListModule,
  SeFeLinkModule,
  SeFeLeftNavModule,
  SeFeMenuModule,
  SeFeModalModule,
  SeFePageModule,
  SeFePaginationModule,
  SeFePopoverModule,
  SeFeSpinnerModule,
  SeFeToastModule,
  SeFeTooltipModule,
  SeFeIconModule.include([
    seFeIconBadgeVerify,
    seFeIconCalendar,
    seFeIconCameraFill,
    seFeIconCaretDown,
    seFeIconCaretUp,
    seFeIconCaretUpDown,
    seFeIconCheckCircle,
    seFeIconCheckCircleOutline,
    seFeIconChevronDown,
    seFeIconChevronLeft,
    seFeIconChevronRight,
    seFeIconChevronUp,
    seFeIconEnvelopeFilled,
    seFeIconFlag,
    seFeIconInfoCircle,
    seFeIconInvoiceThin,
    seFeIconLock,
    seFeIconLockThin,
    seFeIconMore,
    seFeIconMoreVerticle,
    seFeIconMinusCircle,
    seFeIconPencilFill,
    seFeIconPeopleThin,
    seFeIconPerson,
    seFeIconPhoneFill,
    seFeIconPlusCircle,
    seFeIconRemoveCircle,
    seFeIconRemoveCircleOutline,
    seFeIconSearchThin,
    seFeIconShieldFill,
    seFeIconSportAlpineSkiing,
    seFeIconSportAmericanFootball,
    seFeIconSportBadminton,
    seFeIconSportBaseball,
    seFeIconSportBasketball,
    seFeIconSportBiathlon,
    seFeIconSportCricket,
    seFeIconSportCrossCountrySkiing,
    seFeIconSportCurling,
    seFeIconSportFieldHockey,
    seFeIconSportFreeski,
    seFeIconSportGolf,
    seFeIconSportGymnastics,
    seFeIconSportIceHockey,
    seFeIconSportInlineSkating,
    seFeIconSportLacrosse,
    seFeIconSportRugby,
    seFeIconSportSkating,
    seFeIconSportSkiJumping,
    seFeIconSportSkiing,
    seFeIconSportSnowboarding,
    seFeIconSportSoccer,
    seFeIconSportSoftball,
    seFeIconSportSpeedSkating,
    seFeIconSportSwimming,
    seFeIconSportTennis,
    seFeIconSportVolleyball,
    seFeIconSportWrestling,
    seFeIconQuestionCircle,
    seFeIconQuestionCircleOutline,
    seFeIconQuestionThin,
    seFeIconRemove,
    seFeIconWallet,
    seFeIconWarning,
  ]),
]

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AccessDeniedComponent,
    AppComponent,
    BillDetailComponent,
    BillsListComponent,
    DashboardComponent,
    DashboardMembershipInvitesComponent,
    DashboardMobileCalloutComponent,
    DashboardPaymentsComponent,
    DashboardRecommendedActionsComponent,
    DashboardScheduleComponent,
    DashboardTeamsComponent,
    DibsPageComponent,
    GroupsPageComponent,
    HomePageComponent,
    InvalidComponent,
    MembershipInviteModalComponent,
    MessagesPageComponent,
    ModalComponent,
    NginOrderComponent,
    OrderHistoryComponent,
    OrderDetailComponent,
    OrganizationsPageComponent,
    RegistrationDetailComponent,
    RegistrationListComponent,
    RsvpModalComponent,
    SaleActivityCardComponent,
    SaleInfoCardComponent,
    ScheduleComponent,
    SyncScheduleComponent,
    TeamsPageComponent,
    TeamsRemindersComponent,
    WelcomeComponent,
    EventCardComponent,
    RsvpListComponent,
    RsvpListItemComponent,
    VideoStreamingComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    FormsModule,
    HttpClientModule,
    PipesModule,
    ReactiveFormsModule,
    SeUserSettingsModule,
    SeAnalyticsModule.forRoot({
      platProp: 'my_sportsengine_ui',
      currentEnv: environment.name,
      platformSubSec: 'MySE',
    }),
    SeFeApiModule.forRoot({
      domainEnvironments: environment.domainEnvironments,
    }),
    SeFeAuthSupportModule.forRoot(environment),
    SeFeImageUploaderModule.forRoot(environment),
    ...AppSeFeComponentModules,
    SeFeTranslationsModule.forRoot(
      ['en', 'en-gb'],
      environment.local ? '' : `${environment.baseUrl}/se-my-sportsengine`
    ),
    SeMySportsengineFeatureCredentialTableModule,
    SeMySportsengineFeatureHouseholdModule,
    SeMySportsengineFeatureMembershipTableModule,
    SeMySportsengineUiUsavLaunchModule,
  ],

  providers: [
    Title,
    CurrencyPipe,
    { provide: Window, useValue: window },
    { provide: APP_BASE_HREF, useValue: '' },
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
