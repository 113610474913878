import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { HouseholdProfileDuplicatesService, UserProfileDuplicate } from '@se-po/shared-data-access-services'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

export interface Dupe {
  dismissed: boolean
  multiHousehold: boolean
  name: string
  pluralName: string
  profiles: UserProfile[]
  hybrid_id: string
  persona_one_id: number
  persona_two_id: number
}

@Component({
  selector: 'se-po-duplicate-banner',
  templateUrl: './duplicate-banner.component.html',
  styleUrls: ['./duplicate-banner.component.scss']
})
export class DuplicateBannerComponent implements OnChanges {
  @Output() public merge = new EventEmitter<UserProfile[]>()
  @Input() public profiles: UserProfile[]

  public foundDuplicates: UserProfileDuplicate[] = []
  public duplicates: Dupe[] = []
  public showBanner = false

  constructor(
    private householdProfileDuplicatesService: HouseholdProfileDuplicatesService
  ) { }

  public async ngOnChanges(): Promise<void> {
    this.duplicates = []
    this.foundDuplicates = await this.getDuplicateProfiles()
    this.formatDupes()
    this.filterDupes()
  }

  public onMerge(duplicate: Dupe): void {
    const selfDupe = duplicate.profiles.find(p=> p.relationship === 'self')
    const keepProfile = selfDupe || duplicate.profiles[0]
    const mergeProfile = selfDupe ? duplicate.profiles.filter(p => p.id !== selfDupe.id )[0] : duplicate.profiles[1]
    this.merge.emit([keepProfile, mergeProfile])
  }

  public dismissBanner(duplicate: Dupe) {
    this.duplicates.find(dupe => dupe.hybrid_id === duplicate.hybrid_id).dismissed = true
    this.showBanner = !!this.duplicates.find(d => !d.dismissed)
  }

  public filterDupes(): void {
    this.duplicates = this.duplicates.filter(dupe => {
      const profOneCheck = this.profiles.find(profile => profile.persona.id === dupe.persona_one_id)
      const profTwoCheck = this.profiles.find(profile => profile.persona.id === dupe.persona_two_id)
      return profOneCheck && profTwoCheck
    })
  }

  public formatDupes(): void {
    this.foundDuplicates.forEach(foundDuplicate => {
      const suffixes = ['s', 'sh', 'ch', 'x', 'z']
      const pluralSuffix = suffixes.some(suffix => foundDuplicate.persona_one_first_name.endsWith(suffix)) ? 'es' : 's'

      this.duplicates.push({
        name: foundDuplicate.persona_one_first_name,
        multiHousehold: foundDuplicate.dupe_type !== 'intra_account',
        pluralName: `${foundDuplicate.persona_one_first_name}${pluralSuffix}`,
        profiles: [this.build_merge_profile_one(foundDuplicate), this.build_merge_profile_two(foundDuplicate)],
        dismissed:  false,
        hybrid_id: `${foundDuplicate.persona_one_id}-${foundDuplicate.persona_two_id}`,
        persona_one_id: foundDuplicate.persona_one_id,
        persona_two_id: foundDuplicate.persona_two_id
      })
    })

    // Do not show duplicates that the user already tried to hide
   this.showBanner = !!this.duplicates.find(d => !d.dismissed)
  }

  private async getDuplicateProfiles(): Promise<UserProfileDuplicate[]> {
    return await this.householdProfileDuplicatesService.mine().toPromise()
  }

  private build_merge_profile_one(foundDuplicate: UserProfileDuplicate): UserProfile {
    const userProfileOne: UserProfile = this.profiles.find(profile => profile.persona.id === foundDuplicate.persona_one_id)
    if (userProfileOne) return userProfileOne

    return {
      id: foundDuplicate.persona_one_id,
      access: 'none',
      relationship: 'managed',
      persona: {
        id: foundDuplicate.persona_one_id,
        full_name: `${foundDuplicate.persona_one_first_name} ${foundDuplicate.persona_one_last_name}`
      }
    }
  }

  private build_merge_profile_two(foundDuplicate: UserProfileDuplicate): UserProfile {
    const userProfileTwo: UserProfile = this.profiles.find(profile => profile.persona.id === foundDuplicate.persona_two_id)
    if (userProfileTwo) return userProfileTwo

    return {
      id: foundDuplicate.persona_two_id,
      access: 'none',
      relationship: 'managed',
      persona: {
        id: foundDuplicate.persona_two_id,
        full_name: `${foundDuplicate.persona_two_first_name} ${foundDuplicate.persona_two_last_name}`
      }
    }
  }

}
