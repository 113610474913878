import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { MembershipListItem, MembershipListItemsService, UserService } from '@se-po/shared-data-access-services'
import { environment } from '@se-po/shared-environments'
import { Subscription } from 'rxjs'
import { SeFeMenuOptions } from 'se-fe-menu'
import { CredentialSet, PersonaEligibility } from 'se-resource-types/dist/lib/EligibilitySearchService'
import { CurrentUser } from 'se-resource-types/dist/lib/UserService'

@Component({
  selector: 'se-po-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.scss'],
})
export class MembershipListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public personaId: string | number
  @Input() public access: 'viewer' | 'manager' | 'owner'
  @Output() public hasResults: EventEmitter<boolean> = new EventEmitter()

  public listItems: MembershipListItem[]
  public loaded = false

  private currentUser: CurrentUser
  private userSubscription: Subscription

  constructor(
    private membershipListItemsService: MembershipListItemsService,
    private userService: UserService
  ) { }

  public ngOnInit() {
    this.userSubscription = this.userService.current.subscribe((user) => this.currentUser = user)
    this.userService.findCurrent()
  }

  public ngOnDestroy() {
    this.userSubscription.unsubscribe()
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const refresh = Object.values(changes).find((change) => change.firstChange || change.currentValue !== change.previousValue)
    if (refresh) {
      this.loaded = false
      this.listItems = await this.membershipListItemsService.getListItems([this.personaId])
      this.listItems = this.listItems.slice(0, 5)
      this.listItems.forEach((listItem, index) => {
        const eligibilityRuleSetId = `eligibilityRuleSetId=${listItem.personaEligibility.eligibility_rule_set_id}`
        const statusFilters = 'statusFilters=eligible,ineligible'
        const membIDString = 'Membership ID - ' + listItem.personaEligibility.membership_number
        listItem.url = `user/memberships?personFilters=${this.personaId}&${statusFilters}&${eligibilityRuleSetId}`
        listItem.menuOptions = this.getMenuOptions(listItem, index)
        listItem.subtitle = [listItem.validity, ...listItem.subtitle, membIDString].filter(i => !!i)

      })
      this.loaded = true
      this.hasResults.emit(!!this.listItems.length)
    }
  }

  private getMenuOptions(listItem: MembershipListItem, index: number): SeFeMenuOptions {
    const { credentialSet, personaEligibility, triggerCredential } = listItem
    const orgId = personaEligibility.eligibility_rule_set_organization_id
    const menuItems: any = []
    const eligibilityRuleSetName = (personaEligibility as any).eligibility_rule_set?.name || personaEligibility.eligibility_rule_set_name
    if (personaEligibility.action_required_to_become_eligible) {
      menuItems.push({
        text: `Complete ${personaEligibility.unfinished_user_task_count} Requirement(s)`,
        href: listItem.url
      })
    }
    if (this.canPrintCard(credentialSet)) {
      menuItems.push({
        text: 'Print Membership',
        action: () => this.printCard(personaEligibility, credentialSet)
      })
    }
    menuItems.push(
      {
        text: 'Save to Apple Wallet',
        action: () => this.addToWallet(orgId, 'ios')
      },
      {
        text: 'Save to Google Play',
        action: () => this.addToWallet(orgId, 'android')
      }
    )
    return {
      name: `${eligibilityRuleSetName}-${index}`,
      sections: [{ menuItems }]
    }
  }

  private canPrintCard(credentialSet: CredentialSet): boolean {
    return ['eligible', 'upcoming'].includes(credentialSet.eligibility_status.toLowerCase())
  }

  private addToWallet(boss_organization_id: number, device_type: string): void {
    const queryParams = new URLSearchParams({
      persona_id: this.personaId,
      boss_organization_id,
      device_type
    } as any).toString()
    window.open(`${environment.returnUrls.memberships}download?${queryParams}`, '_blank')
  }

  // From eligibility_list_ui
  private printCard(personaEligibility: PersonaEligibility, credentialSet: CredentialSet): void {
    const params = {
       user_uuid: this.currentUser.uuid,
       issuing_organization_id: personaEligibility.eligibility_rule_set_organization_id,
       affiliation_organization_id: credentialSet.organization_id,
       scope: 'roster_persona',
       roster_personas: [{ persona_id: this.personaId }]
     } as any

   const url = `${environment.membershipService}/cards/print`
   const form = window.document.createElement('form')
   form.target = '_blank'
   form.method = 'POST'
   form.action = url
   Object.keys(params).forEach((key) => {
     this.addHiddenField(form, key, params[key])
   })
   window.document.body.appendChild(form)
   form.submit()
   window.document.body.removeChild(form)
 }

 private addHiddenField(form: HTMLFormElement, name: string, value: any): void {
   if (Array.isArray(value)) {
     value.forEach((val) => {
       this.addHiddenField(form, name + '[]', val)
     })
   } else if (typeof value === 'object' && value !== null) {
     Object.keys(value).forEach((key) => {
       this.addHiddenField(form, name + '[' + key + ']', value[key])
     })
   } else {
     const input = window.document.createElement('input')
     input.type = 'hidden'
     input.name = name
     input.value = value
     form.appendChild(input)
   }
 }

}
