<se-fe-modal #modal>
  <div *ngIf="!loading" seFeModalHeader>
    <se-fe-modal-header
      seFeDataTitle="{{ translationKey + 'TITLE' | translate }}"
      (seFeButtonAction)="modal.close()">
    </se-fe-modal-header>
  </div>
  <div *ngIf="!loading" seFeModalContent>
    <se-fe-grid seFeGridOpts>
      <se-fe-grid-cell seFeGridOpts="1/1">
        <se-po-persona-avatar
          [persona]="exactNameMatch ? keepProfile?.persona : mergeProfile?.persona">
        </se-po-persona-avatar>
      </se-fe-grid-cell>
      <se-fe-grid-cell *ngIf="!exactNameMatch" seFeGridOpts>
        <div class="merge-content">
          {{ (translationKey + 'DESCRIPTIONS.INEXACT_MATCH.LINE_ONE') | translate: { mergeName: mergeProfile?.persona?.full_name, keepName: keepProfile?.persona?.full_name } }}
        </div>
        <div class="merge-content">
          {{ (translationKey + 'DESCRIPTIONS.INEXACT_MATCH.LINE_TWO') | translate: { mergeName: mergeProfile?.persona?.full_name } }}
        </div>
      </se-fe-grid-cell>
      <se-fe-grid-cell *ngIf="exactNameMatch" seFeGridOpts>
        <div *ngIf="!multiHouseholdMerge" class="merge-content">
          {{ (translationKey + 'DESCRIPTIONS.EXACT_MATCH.SINGLE_HOUSEHOLD') | translate: { mergeName: mergeProfile?.persona?.full_name } }}
        </div>
        <div *ngIf="multiHouseholdMerge" class="merge-content">
          {{ (translationKey + 'DESCRIPTIONS.EXACT_MATCH.MULTI_HOUSEHOLD') | translate: { mergeName: mergeProfile?.persona?.full_name, keepName: keepProfile?.persona?.full_name } }}
        </div>
        <div class="merge-content">
          {{ (translationKey + 'DESCRIPTIONS.EXACT_MATCH.LINE_TWO') | translate }}
        </div>
      </se-fe-grid-cell>
      <se-fe-grid-cell *ngIf="mergeError" seFeGridOpts="1/1">
        <se-fe-alert class="merge-alert"
          seFeDataType="error">
          {{ mergeError }}
          <se-fe-button
            seFeDataEmphasis="low"
            (click)="mergePersonas()">
            {{ 'RETRY' | translate }}
          </se-fe-button>
        </se-fe-alert>
      </se-fe-grid-cell>
    </se-fe-grid>
  </div>

  <div seFeModalFooter>
    <se-fe-modal-footer
      [seFeDataFooterButtons]="options">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>

<se-fe-toast></se-fe-toast>
