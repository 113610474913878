import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { HouseholdGuardianInvitesService } from '@se-po/shared-data-access-services'
import { AddGuardianComponent } from '@se-po/shared-ui-add-guardian'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-new-guardian-modal',
  templateUrl: './new-guardian-modal.component.html',
  styleUrls: ['./new-guardian-modal.component.scss'],
})
export class NewGuardianModalComponent {
  @Output() public inviteSent: EventEmitter<void> = new EventEmitter()
  @ViewChild('modal') public modal: SeFeModalComponent
  @ViewChild('footer') public footer: SeFeModalFooterComponent

  @ViewChild('addGuardian') public addGuardian: AddGuardianComponent

  public modalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.modal.close()
      },
    },
    {
      primary: true,
      label: 'Send Invite',
      action: () => {
        this.submit()
      },
    },
  ]

  public error: string
  public persona: Persona

  constructor(
    private guardianInviteService: HouseholdGuardianInvitesService,
    private toastService: SeFeToastService
  ) { }


  public open(persona: Persona, emailParam?: string): void {
    this.addGuardian.reset(emailParam)
    this.error = null
    this.persona = persona
    this.modal.open(null)
  }

  public submit(): void {
    this.error = null
    if (this.addGuardian.isValid()){
      this.guardianInviteService.create(
        this.addGuardian.value.access,
        this.addGuardian.value.email,
        this.persona.id,
        'mySe'
      ).subscribe({
        next: result => {
          this.toastService.success(
            `Invite has been sent to ${this.addGuardian.value.email}`
          )
          this.modal.close()
          this.inviteSent.emit()
        },
        error: err => {
          const message = `Error sending invite to ${this.addGuardian.value.email}`
          this.error = err?.error?.error?.messages?.[0] || message
          this.toastService.error(message)
          this.footer.cancelSpinner()
        }
      })
    }
    else {
      this.footer.cancelSpinner()
    }
  }

}
