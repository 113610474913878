import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms'
import { FormGroupValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactInfoComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactInfoComponent),
      multi: true,
    }
  ]
})
export class ContactInfoComponent extends FormGroupValueAccessorDirective implements OnInit {
  public static timestamp = Date.now()

  @Input() public showHelp = false
  @Output() init = new EventEmitter<ContactInfoComponent>()

  public form: UntypedFormGroup
  public phoneHelpId: string
  public popoverName: string

  constructor(
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private seFeTranslationsLoader: SeFeTranslationsLoader,
  ) {
    super(injector)
  }

  public ngOnInit(): void {
    super.ngOnInit()
    this.phoneHelpId = `phone-control-help-${ContactInfoComponent.timestamp}`
    this.popoverName = `phone-learn-more-${ContactInfoComponent.timestamp}`
    this.initTranslations()
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-contact-info/assets/translations').then(() => {
      this.loaded = true
      this.init.emit(this)
      this.init.complete()
    })
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      phone: ['', Validators.required]
    })
  }

  public setForm(value: any): void {
    this.form.setValue({
      email: value?.email || '',
      phone: value?.phone || ''
    })
  }
}
