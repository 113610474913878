import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AnalyticsService } from '@se-po/shared-data-access-services'
import { Dupe } from '../duplicate-banner/duplicate-banner.component'

@Component({
  selector: 'se-po-duplicate-banner-instance',
  templateUrl: './duplicate-banner-instance.component.html',
  styleUrls: ['./duplicate-banner-instance.component.scss'],
})
export class DuplicateBannerInstanceComponent implements OnInit {
  @Input() public duplicate: Dupe
  @Output() public merge = new EventEmitter<Dupe>()
  @Output() public dismiss = new EventEmitter<Dupe>()

  public householdString: string

  constructor(
    private analyticsService: AnalyticsService
  ) {
    //noop
  }

  public ngOnInit(): void {
    this.householdString = this.duplicate.multiHousehold ? 'Mutli' : 'Single'
    this.analyticsService.seEvent(`Household.MergeBanner.${this.householdString}.View`, 8)
  }

  public onMerge(): void {
    this.merge.emit(this.duplicate)
    this.analyticsService.seEvent(`Household.MergeBanner.${this.householdString}.Merge`, 8)
  }

  public dismissBanner(): void {
    this.dismiss.emit(this.duplicate)
    this.analyticsService.seEvent(`Household.MergeBanner.${this.householdString}.DismissMerge`, 8)
  }
}
