<se-fe-banner *ngIf="!duplicate.dismissed"
  seFeDataTitle="2 {{ duplicate.pluralName }} found in your household - are they the same person?"
  seFeDataType="warning"
  [seFeShowIcon]="false">
  <span seFeBannerSubtitle>
    We've found these profiles have matching details. We recommend merging them into a single profile.
  </span>
  <se-fe-button seFeBannerAction
    class="first-button"
    seFeDataEmphasis="medium"
    (click)="dismissBanner()">
    No
  </se-fe-button>
  <se-fe-button seFeBannerAction
    seFeDataEmphasis="high"
    (click)="onMerge()">
    Yes
  </se-fe-button>
</se-fe-banner>
