import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { SeFeModalButton, SeFeModalComponent } from 'se-fe-modal'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { MergePersonaModalSubmitter } from './merge-persona-modal-submit.service'
import { SeFeToastService } from 'se-fe-toast'
import { SeFeTranslationsLoader } from 'se-fe-translations'
import { AnalyticsService } from '@se-po/shared-data-access-services'

@Component({
  selector: 'se-po-merge-persona-modal',
  templateUrl: './merge-persona-modal.component.html',
  styleUrls: ['./merge-persona-modal.component.scss']
})
export class MergePersonaModalComponent implements OnInit, OnDestroy {
  @Output() public personaMerged = new EventEmitter<UserProfile>()
  @ViewChild('modal') public modal: SeFeModalComponent

  public description: string
  public exactNameMatch = false
  public keepProfile: UserProfile
  public loading: boolean
  public mergeError = ''
  public mergeProfile: UserProfile
  public multiHouseholdMerge = false
  public translationKey = 'SE_PO_SHARED_FEATURE_MERGE_PERSONA_MODAL.'
  public options: SeFeModalButton[] = [
    {
      label: 'Cancel',
      action: () => this.modal.close()
    },
    {
      primary: true,
      label: 'Merge',
      action: () => this.mergePersonas()
    }
  ]

  private _subscriptions: Subscription[] = []

  constructor(
    private analyticsService: AnalyticsService,
    private submitter: MergePersonaModalSubmitter,
    private toastService: SeFeToastService,
    public translateService: TranslateService,
    public seFeTranslationsLoader: SeFeTranslationsLoader
  ) {
    // noop
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true
    this.loadTranslations()
  }

  async loadTranslations(): Promise<void> {
    await this.seFeTranslationsLoader.load('shared/feature-merge-persona-modal/assets/translations').then(() => {
      this.loading = false
    })
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public open(keepProfile: UserProfile, mergeProfile: UserProfile, description: string) {
    this.mergeError = ''
    this.description = description
    this.keepProfile = keepProfile
    this.mergeProfile = mergeProfile
    this.handleMultiHouseholdMerge()
    this.checkExactNameMatch()
    this.modal.open(null)
  }

  public checkExactNameMatch() {
    this.exactNameMatch = false
    if (this.keepProfile.persona.full_name.trim() === this.mergeProfile.persona.full_name.trim()) {
      this.exactNameMatch = true
    }
  }

  public handleMultiHouseholdMerge() {
    this.multiHouseholdMerge = false
    if(this.mergeProfile.access !== 'owner' && this.keepProfile.access === 'owner') this.multiHouseholdMerge = true
    if( this.keepProfile.access !== 'owner') {
      const orginalKeepProfile = this.keepProfile
      this.multiHouseholdMerge = true
      this.keepProfile = this.mergeProfile
      this.mergeProfile = orginalKeepProfile
    }
  }

  public mergePersonas() {
    const typeSuffix = this.multiHouseholdMerge ? 'Multi' : 'Single'
    this.analyticsService.seEvent(`Household.MergeModal.${typeSuffix}`, 8)
    this._subscriptions.push(this.submitter.submit(this.keepProfile, this.mergeProfile, this.description)
      .subscribe({
        next: _ => {
          if (this.submitter.error) {
            this.mergeError = this.submitter.error
          } else {
            this.personaMerged.emit(this.mergeProfile)
            this.modal.close()
            this.toastService.success(
              `Successfully merged ${this.mergeProfile.persona.name} into ${this.keepProfile.persona.name}`)
          }
        }
      }))
  }

}
