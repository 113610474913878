import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedUiAddGuardianModule } from '@se-po/shared-ui-add-guardian'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeToastModule } from 'se-fe-toast'
import { NewGuardianModalComponent } from './new-guardian-modal/new-guardian-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeModalModule,
    SeFeToastModule,
    SharedUiAddGuardianModule,
    SharedUiEmailModule
  ],
  declarations: [NewGuardianModalComponent],
  exports: [NewGuardianModalComponent],
})
export class SeMySportsengineFeatureNewGuardianModalModule {}
