import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeMySportsengineFeatureHouseholdSidePanelModule } from '@se-po/se-my-sportsengine-feature-household-side-panel'
import { SeMySportsengineUiHouseholdAvatarModule } from '@se-po/se-my-sportsengine-ui-household-avatar'
import { SharedFeatureEditPersonaModalModule } from '@se-po/shared-feature-edit-persona-modal'
import { SharedFeatureMergePersonaModalModule } from '@se-po/shared-feature-merge-persona-modal'
import { SharedFeatureRemovePersonaModalModule } from '@se-po/shared-feature-remove-persona-modal'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeBannerModule } from 'se-fe-banner'
import { SeFeButtonModule } from 'se-fe-button'

import { SeMySportsengineFeatureNewGuardianModalModule } from '@se-po/se-my-sportsengine-feature-new-guardian-modal'
import { SeMySportsengineFeatureNewPersonModalModule } from '@se-po/se-my-sportsengine-feature-new-person-modal'
import { SeFeHeaderModule } from 'se-fe-header'
import { seFeIconInfoCircle, SeFeIconModule } from 'se-fe-icon'
import { SeFePageModule } from 'se-fe-page'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { DuplicateBannerComponent } from './duplicate-banner/duplicate-banner.component'
import { HouseholdComponent } from './household/household.component'
import { DuplicateBannerInstanceComponent } from './duplicate-banner-instance/duplicate-banner-instance.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeBannerModule,
    SeFeButtonModule,
    SeFeHeaderModule,
    SeFeIconModule.include([seFeIconInfoCircle]),
    SeFePageModule,
    SeFePopoverModule,
    SeFeTranslationsModule,
    SeMySportsengineFeatureNewGuardianModalModule,
    SeMySportsengineFeatureNewPersonModalModule,
    SeMySportsengineFeatureHouseholdSidePanelModule,
    SeMySportsengineUiHouseholdAvatarModule,
    SharedFeatureEditPersonaModalModule,
    SharedFeatureMergePersonaModalModule,
    SharedFeatureRemovePersonaModalModule
  ],
  declarations: [
    DuplicateBannerComponent,
    DuplicateBannerInstanceComponent,
    HouseholdComponent
  ],
  exports: [HouseholdComponent]
})
export class SeMySportsengineFeatureHouseholdModule {}
