import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { DobParser } from '@se-po/shared-data-access-parsers'
import { DateService } from '@se-po/shared-data-access-services'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { Subscription } from 'rxjs'
import { SeFeExcludedDates } from 'se-fe-datepicker'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-dob-select',
  templateUrl: './dob-select.component.html',
  styleUrls: ['./dob-select.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class DobSelectComponent implements OnInit, OnDestroy {
  @Input() public seFeDataLabel?: string

  public age: string
  public datepickerLimit: SeFeExcludedDates[] = []
  public errorMessages: any
  public loaded: boolean
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control

  private subscriptions: Subscription[] = []

  constructor(private dateService: DateService,
              private seFeTranslationsLoader: SeFeTranslationsLoader,
              private translateService: TranslateService)
  { }

  public get isRequired(): boolean {
    // to get datepicker component to display label as optional,
    // need to separately pass through whether form field is required
    const validators = this.control?.validator && this.control.validator({} as AbstractControl)
    return !!(validators && validators.required)
  }

  public get control(): AbstractControl {
    return this.ngControl?.control
  }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.control?.parent
  }

  public ngOnInit(): void {
    this.loaded = false
    this.datepickerLimit = [{ start: this.dateService.mmTomorrow() }]
    this.initTranslations()
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
    this.subscriptions.length = 0
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-dob-select/assets/translations').then(() => {
      this.errorMessages = {
        dateInvalid: this.translateService.instant('SE_PO_UI_DOB_SELECT.ERRORS.dateInvalid'),
        dateOutOfRange: this.translateService.instant('SE_PO_UI_DOB_SELECT.ERRORS.dateOutOfRange'),
        dob18: this.translateService.instant('SE_PO_UI_DOB_SELECT.ERRORS.dob18'),
        required: this.translateService.instant('SE_PO_UI_DOB_SELECT.ERRORS.required')
      }
      this.loaded = true
      this.initFormatters()
    })
  }

  private initFormatters(): void {
    this.formatDob(this.control.value)
    this.subscriptions.push(this.control.valueChanges.subscribe(val => {
      this.formatDob(val)
    }))
  }

  private formatAge(dateOfBirth: string): void {
    let ageVal: number
    if (!dateOfBirth || !DobParser.validMm(dateOfBirth)) {
      ageVal = null
    }
    else {
      ageVal = this.dateService.ageFromIso(DobParser.mmToIso(dateOfBirth))
    }
    if (ageVal !== null && ageVal >= 0) {
      this.age = this.translateService.instant('SE_PO_UI_DOB_SELECT.FORM_SUFFIXES.age', { age: ageVal })
    }
    else {
      this.age = ' '
    }
  }

  private formatDob(dob: string) {
    const fmt = DobParser.formatAsMm(dob)
    if (fmt !== dob) {
      this.control.setValue(fmt)
    }
    this.formatAge(fmt)
  }

}
