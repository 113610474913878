<se-fe-modal #modal>

  <div seFeModalHeader>
    <se-fe-modal-header
      seFeDataTitle="Grant Access">
    </se-fe-modal-header>
  </div>

  <div seFeModalContent>
    <se-fe-alert
      seFeDataType="error"
      *ngIf="error">
      {{ error }}
    </se-fe-alert>
    <div>
      <p>Grant access to {{ persona?.first_name }}'s information and help manage their sports life by inviting someone.</p>
    </div>
    <se-po-add-guardian #addGuardian>
    </se-po-add-guardian>
  </div>

  <div seFeModalFooter>
    <se-fe-modal-footer #footer
      [seFeDataFooterButtons]="modalButtons">
    </se-fe-modal-footer>
  </div>

</se-fe-modal>
<se-fe-toast></se-fe-toast>
