import { Component, OnInit } from '@angular/core'
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { SeFeSelectOption } from 'se-fe-form-field-select'
import { SeFeFormService } from 'se-fe-form-service'

@Component({
  selector: 'se-po-add-guardian',
  templateUrl: './add-guardian.component.html',
  styleUrls: ['./add-guardian.component.scss']
})
export class AddGuardianComponent implements OnInit {

  public accessFieldOptions: SeFeSelectOption[] = [
    {
      label: 'Manage',
      value: 'manager'
    },
    {
      label: 'View',
      value: 'viewer'
    }
  ]
  public form: UntypedFormGroup

  constructor(
    private formBuilder: FormBuilder,
    public formService: SeFeFormService
  ) { }

  public get value(): any {
    return this.form.value
  }

  public isValid(): boolean {
    return this.formService.isValid(this.form)
  }

  public ngOnInit(): void {
    this.createForm()
  }

  public createForm(): void {
    this.form = this.formBuilder.group(
      {
        email: ['', Validators.required],
        access: ['', Validators.required]
      }
    )
  }

  public reset(email?: string): void {
    this.form.reset({
      email: email || '',
      access: '',
    })
  }

}
