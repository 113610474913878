import { AbstractControl, Validators } from '@angular/forms'
import { DateTime } from 'luxon'

import { DobParser, PhoneParser } from '@se-po/shared-data-access-parsers'

export class CustomValidators extends Validators {

  public static dobValid(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return null
    if (DobParser.validMm(control.value)) return null
    return {
      dobValid: {
        value: control.value,
        message: 'Invalid date'
      }
    }
  }

  public static dob13(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return null
    if (!DobParser.validMm(control.value)) return null

    const isoDate = DobParser.mmToIso(control.value) || ''
    const val: DateTime = DateTime.fromISO(isoDate)
    if (!val) return null

    const diff = DateTime.now().diff(val, 'years')
    if (diff && (diff?.years || 0) < 13) {
      return {
        dob13: {
          value: control.value,
          message: `You must be at least 13 years old. (you are ${diff?.years || '?'})`
        }
      }
    }
    return null
  }

  public static dob18(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return null
    if (!DobParser.validMm(control.value)) return null

    const isoDate = DobParser.mmToIso(control.value) || ''
    const val: DateTime = DateTime.fromISO(isoDate)
    if (!val) return null

    const diff = DateTime.now().diff(val, 'years')
    if (diff && (diff?.years || 0) < 18) {
      return {
        dob18: {
          value: control.value,
          message: 'You are not eligible to create a SportsEngine account. Please ask your parent or guardian to create an account.'
        }
      }
    }
    return null
  }

  public static code7(control: AbstractControl): { [key: string]: any } | null {
    const val = PhoneParser.toN(control.value)
    if ((!val && !control.value) || val.length === 7) return null

    return {
      code7: {
        value: control.value,
        message: 'Please enter a valid 7 digit code'
      }
    }
  }

  public static phone10(control: AbstractControl): { [key: string]: any } | null {
    const val = PhoneParser.toN(control.value)
    if ((!val && !control.value) || val.length === 10) return null

    return {
      phone10: {
        value: control.value,
        message: 'Please enter a valid 10 digit phone number'
      }
    }
  }

  public static phone10To12(control: AbstractControl): { [key: string]: any } | null {
    const val = PhoneParser.toN(control.value)
    if ((!val && !control.value) || val.length === 10 || val.length === 11 || val.length === 12) return null

    return {
      phone10To12: {
        value: control.value,
        message: 'Please enter a valid phone number'
      }
    }
  }

  public static password(control: AbstractControl): { [key: string]: any } | null {
    // Password must be at least 8 characters and include
    //       one uppercase letter, one lowercase letter,
    //       and one number or symbol.
    const err: { [key: string]: any } = {}
    const val: string = control.value as string || ''
    const setErr = (key: string, msg: string) => {
      err[key] = { value: val, message: msg }
    }
    let re = /[A-Z]/
    if (!re.exec(val)) setErr('passwordUpper', 'Must include an uppercase letter')

    re = /[a-z]/
    if (!re.exec(val)) setErr('passwordLower', 'Must include a lowercase letter')

    re = /[^a-zA-Z]/
    if (!re.exec(val)) setErr('passwordNonAlpha', 'Must include a number or symbol')

    if (val.length < 8) setErr('passwordMin', 'Must be at least 8 characters')

    if (!Object.keys(err).length) return null

    return err
  }

  public static firstLastName(control: AbstractControl): { [key: string]: any } | null {
    const firstMissing = control.get('first').hasError('required')
    const lastMissing = control.get('last').hasError('required')
    if (firstMissing) {
      if (lastMissing) {
        return {
          firstLastRequired: {
            value: control.value,
            message: 'Enter your first and last names'
          }
        }
      }
      return {
        firstRequired: {
          value: control.value,
          message: 'Enter your first name'
        }
      }
    }
    if (lastMissing) {
      return {
        lastRequired: {
          value: control.value,
          message: 'Enter your last name'
        }
      }
    }
    return null
  }

  public static checkboxSelected(control: AbstractControl): { [key: string]: any } | null {
    // validate something selected in a form group consisting of individual checkbox controls
    if (!Object.keys(control.value).some(key => !!control.value[key])) {
      return {
        selectionRequired: {
          value: control.value,
          message: 'Please select at least one option'
        }
      }
    }
    return null
  }

  public static gradYear(dateOfBirthControl: AbstractControl, gradYearControl: any): { [key: string]: any } | null {
    gradYearControl.helpText = undefined
    const dateOfBirth = dateOfBirthControl.value
    const gradYear = gradYearControl.value

    if (!dateOfBirth || !gradYear) {
      return
    }

    const { month, day } = DateTime.now()
    const gradYearDate = DateTime.fromFormat(`${month}/${day}/${gradYear}`, 'm/d/yyyy')
    const diff = gradYearDate.diff(DateTime.fromFormat(dateOfBirth, 'mm/dd/yyyy'), 'years')
    const ageAtGraduation = Math.round(diff.years)
    if (ageAtGraduation > 16 && ageAtGraduation < 20) {
      return
    }

    gradYearControl.helpText = `Graduation Year is ${ageAtGraduation} years from Birthday. Are you sure this is correct?`
  }

  public static childOver18(dateOfBirthControl: any): { [key: string]: any } | null {
    const dateOfBirth = dateOfBirthControl?.value
    if (!dateOfBirth) return null
    if (!DobParser.validMm(dateOfBirth)) return null

    const isoDate = DobParser.mmToIso(dateOfBirth) || ''
    const val: DateTime = DateTime.fromISO(isoDate)
    if (!val) return null

    dateOfBirthControl.helpText = undefined

    const diff = DateTime.now().diff(val, 'years')
    if (diff && (diff?.years || 0) < 18) {
      return
    }
    dateOfBirthControl.helpText = 'Child profiles must be under 18; please enter a valid birthday.'
  }

}
