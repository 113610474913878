import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { PersonaInfo } from '@se-po/shared-data-access-models'
import { DobParser } from '@se-po/shared-data-access-parsers'
import { PersonaInfoComponent } from '@se-po/shared-ui-persona-info'
import { SeFeFormService } from 'se-fe-form-service'

@Component({
  selector: 'se-po-add-child-profile',
  templateUrl: './add-child-profile.component.html',
  styleUrls: ['./add-child-profile.component.scss'],
})
export class AddChildProfileComponent implements OnInit {
  @ViewChild('childInfo') public childInfo: PersonaInfoComponent

  @Input() public optionalFields: string[] = ['gradYear']

  public ready = false
  public form: UntypedFormGroup

  constructor(
    private fb: FormBuilder,
    private formService: SeFeFormService,
  ) { }

  public get value(): PersonaInfo {
    const info = { ...this.form.value.childInfo }
    info.dateOfBirth = DobParser.mmToIso(info.dateOfBirth)
    return info
  }

  public isValid(): boolean {
    return this.formService.isValid(this.childInfo.form)
  }

  public ngOnInit(): void {
    this.initForm()
    this.ready = true
  }

  public reset(): void {
    this.ready = false
    setTimeout(() => {
      this.form.reset()
      this.ready = true
    })
  }

  private initForm(): void {
    this.form = this.fb.group({
      childInfo: []
    })
  }
}
