import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeGridModule } from 'se-fe-grid'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeToastModule } from 'se-fe-toast'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { MergePersonaModalComponent } from './merge-persona-modal/merge-persona-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeGridModule,
    SeFeModalModule,
    SeFeToastModule,
    SharedUiPersonaAvatarModule,
    TranslateModule
  ],
  declarations: [MergePersonaModalComponent],
  exports: [MergePersonaModalComponent],
})
export class SharedFeatureMergePersonaModalModule {}
