import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldSelectModule } from 'se-fe-form-field-select'
import { seFeIconInfoCircle, SeFeIconModule } from 'se-fe-icon'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeTooltipModule } from 'se-fe-tooltip'
import { AddGuardianComponent } from './add-guardian/add-guardian.component'

@NgModule({
  declarations: [AddGuardianComponent],
  exports: [AddGuardianComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeFormFieldModule,
    SeFeFormFieldSelectModule,
    SeFeIconModule.include([
      seFeIconInfoCircle
    ]),
    SeFePopoverModule,
    SeFeTooltipModule,
    SharedUiEmailModule,
],
})
export class SharedUiAddGuardianModule {}
